.authentication {
  min-height: 100vh;
  .display-1 {
    font-size: 8.5rem;
    margin-block: 0px !important;
    font-weight: 500;
  }
  .authentication-brand {
    &.desktop-logo {
      display: block;
    }
    &.desktop-dark {
      display: none;
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    background-color: rgba(255, 255, 255, 0.05);
    color: rgba(255, 255, 255, 0.5) !important;
  }
  .swiper-pagination-bullet {
    opacity: 0.1;
  }
  .swiper-pagination-bullet-active {
    opacity: 0.5;
  }
  .google-svg {
    width: 0.75rem;
    height: 0.75rem;
    margin-inline-end: 0.5rem;
  }
  .authentication-barrier {
    position: relative;
    span {
      position: relative;
      z-index: 2;
    }
    &:before {
      position: absolute;
      content: "";
      width: 45%;
      height: 0.125rem;
      background-image: linear-gradient(to left, transparent, $default-border);
      border-radius: 50%;
      inset-inline-end: 0;
      inset-block-start: 0.563rem;
      z-index: 1;
    }
    &:after {
      position: absolute;
      content: "";
      width: 45%;
      height: 0.125rem;
      background-image: linear-gradient(to left, $default-border, transparent);
      border-radius: 50%;
      inset-inline-start: 0;
      inset-block-start: 0.563rem;
      z-index: 1;
    }
  }
  &.coming-soon,
  &.under-maintenance {
    .authentication-cover {
      .aunthentication-cover-content {
        width: 100%;
        height: 100%;
        padding: 3rem;
        backdrop-filter: none;
        background-color: transparent;
        &:before,
        &:after {
          display: none;
        }
        .coming-soon-time,
        .under-maintenance-time {
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
            0 2px 10px 0 rgba(0, 0, 0, 0.12);
          background-color: $white-1;
          color: $white;
        }
        .authentication-brand {
          width: auto;
          height: auto;
          border: 0;
        }
      }
    }
  }
  .coming-soom-image-container,
  .under-maintenance-image-container {
    img {
      width: 100%;
      height: auto;
    }
  }
  .authentication-cover {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .aunthentication-cover-content {
      width: 70%;
      height: 37.5rem;
      background-color: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(1.875rem);
      padding: 2rem;
      position: relative;
      img {
        margin: 0 auto;
        width: 13.75rem;
        height: 13.75rem;
      }
    }
  }
  &.authentication-basic {
    .desktop-dark {
      display: none;
    }
    .desktop-logo {
      display: block;
    }
    .form-control-lg {
      &#one,
      &#two,
      &#three,
      &#four {
        padding-inline: 0;
      }
    }
  }
}
@media(max-width:320px) {
  .authentication .display-1 {
    font-size: 6.5rem;
  }
}
[data-theme-mode="dark"] {
  .authentication {
    &.authentication-basic {
      .desktop-dark {
        display: block;
      }
      .desktop-logo {
        display: none;
      }
    }
    .authentication-brand {
      &.desktop-logo {
        display: none;
      }
      &.desktop-dark {
        display: block;
      }
    }
  }
}
.authentication {
  .desktop-logo,
  .desktop-dark {
    height: 2rem;
    line-height: 2rem;
  }
}

.coming-soon {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url(../images/landing/backgrounds/2.jpg);
  &::before {
    content: "";
    background: linear-gradient(to right, var(--primary06) 0%, #05c3fb91 100%);
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .page {
    position: relative;
  }
  .btn.btn-icon {
    background: $white-1;
    color: $white;
    border-radius: 50%;
  }
}
.autentication-bg {
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-image: url(../images/landing/backgrounds/2.jpg);
  z-index: 1;
  &::before {
    content: "";
    background: linear-gradient(to right, var(--primary06) 0%, #05c3fb91 100%);
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: -1;
  }
}
