.open-file {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1;
  inset-inline-end: 0;
  inset-inline-start: 0;
  inset-block-start: 0;
}
.file-manager-image img {
  background: rgba(var(--warning-rgb), 0.2);
  border-radius: 50px;
  padding: 6px;
  width: 75px;
}
.file-img {
  padding: 0.75rem;
  border-radius: 50%;
  vertical-align: top;
  svg {
    width: 24px;
    height: 24px;
  }
}
.file-dropdown {
  position: relative;
  z-index: 2;
}
@media screen and (min-width: 1400px) {
  .file-manager-list {
    height: 133px;
  }
}
