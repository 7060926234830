#particles-js {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: $custom-white;
}
.error-page {
    position: absolute;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.error-text {
    font-size: 8.25rem;
    font-weight: 500;
    line-height: 1;
}
@media (max-width: 575.98px) {
    .error-text {
        font-size: 3rem;
    }
}