.input-group-text {
    border-color: $input-border;
    font-size: 0.875rem;
    border-radius: 0.3125rem;
    background-color: rgb(var(--light-rgb));
    color: $default-text-color;
    letter-spacing: 0;
    .form-control {
        border: 0;
        border-start-start-radius: 0;
        border-end-start-radius: 0;
    }
}