.progress {
    background-color: $light;
    height: 0.75rem;
    border-radius: 0.25rem;
}
.progress-stacked {
    background-color: $light;
}
.progress-bar {
    color: $white;
    background-color: $primary;
}

@keyframes progress-animate {
    0% {
        width: 0;
    }
}
.progress-animate {
    position: relative;
    border-radius: 0.625rem;
    .progress-bar {
        position: relative;
        border-radius: 0.625rem;
        animation:  2s progress-animate;
    }
}
/* Start:: progressbar-height */
.progress,.progress-stacked {
    &.progress-xs {
        height: .3125rem;
    }
    &.progress-sm {
        height: 0.5rem;
    }
    &.progress-lg {
        height: 1rem;
    }
    &.progress-xl {
        height: 1.25rem;
    }
}
/* End:: progressbar-height */

/* Start:: custom-progress */
.progress-custom {
    overflow: visible;
    position: relative;
    .progress-bar {
        position: relative;
        overflow: visible;
        &:after {
            content: "";
            width: 1.15rem;
            height: 1.15rem;
            border-radius: 50%;
            box-shadow: 0 0.313rem 0.313rem $primary-02;
            background: $white;
            position: absolute;
            inset-inline-end: -0.375rem;
            inset-block-start: -0.375rem;
            border: 0.25rem solid $primary;
        }
        &.bg-secondary:after {
            border: 0.25rem solid $secondary;
            box-shadow: 0 0.313rem 0.313rem rgba(var(--secondary-rgb),0.2);
        }
        &.bg-warning:after {
            border: 0.25rem solid $warning;
            box-shadow: 0 0.313rem 0.313rem rgba(var(--warning-rgb),0.2);
        }
        &.bg-info:after {
            border: 0.25rem solid $info;
            box-shadow: 0 0.313rem 0.313rem rgba(var(--info-rgb),0.2);
        }
        &.bg-success:after {
            border: 0.25rem solid $success;
            box-shadow: 0 0.313rem 0.313rem rgba(var(--success-rgb),0.2);
        }
        &.bg-danger:after {
            border: 0.25rem solid $danger;
            box-shadow: 0 0.313rem 0.313rem rgba(var(--danger-rgb),0.2);
        }
    }
    .progress-bar-title{
        padding: 0.313rem 0.625rem;
        margin: 0;
        background: $primary;
        border-radius: 0.313rem 0 0 0.313rem;
        font-size: 0.625rem;
        font-weight: 600;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        inset-block-start: -0.438rem;
        inset-inline-start: 0;
        z-index: 1;
        &:after{
            content: "";
            border-inline-start: 1.063rem solid $primary;
            border-block-start: 0.688rem solid transparent;
            border-block-end: 0.688rem solid transparent;
            position: absolute;
            inset-block-start: 0;
            inset-inline-end: -1.063rem;
        }
        &.bg-secondary {
            background-color: $secondary;
            &:after {
                border-inline-start: 1.063rem solid $secondary;
            }
        }
        &.bg-success {
            background-color: $success;
            &:after {
                border-inline-start: 1.063rem solid $success;
            }
        }
        &.bg-warning {
            background-color: $warning;
            &:after {
                border-inline-start: 1.063rem solid $warning;
            }
        }
        &.bg-danger {
            background-color: $danger;
            &:after {
                border-inline-start: 1.063rem solid $danger;
            }
        }
        &.bg-info {
            background-color: $info;
            &:after {
                border-inline-start: 1.063rem solid $info;
            }
        }
    }
    .progress-bar-value{
        width: 1.875rem;
        height: 1.25rem;
        line-height: 1.25rem;
        border-radius: 0.188rem;
        background: $primary;
        box-shadow: 0 0.313rem 0.313rem rgba(0,0,0,0.4);
        font-size: 0.625rem;
        font-weight: 600;
        color: $white;
        text-align: center;
        position: absolute;
        inset-block-end: 1.5rem;
        inset-inline-end: -0.688rem;
        &:after{
            content: "";
            border-block-start: 0.438rem solid $primary;
            border-inline-start: 0.438rem solid transparent;
            border-inline-end: 0.438rem solid transparent;
            position: absolute;
            inset-block-end: -0.375rem;
            inset-inline-start: 28%;
        }
        &.bg-secondary {
            background-color: $secondary;
            &:after {
                border-block-start: 0.438rem solid $secondary;
            }
        }
        &.bg-success {
            background-color: $success;
            &:after {
                border-block-start: 0.438rem solid $success;
            }
        }
        &.bg-warning {
            background-color: $warning;
            &:after {
                border-block-start: 0.438rem solid $warning;
            }
        }
        &.bg-danger {
            background-color: $danger;
            &:after {
                border-block-start: 0.438rem solid $danger;
            }
        }
        &.bg-info {
            background-color: $info;
            &:after {
                border-block-start: 0.438rem solid $info;
            }
        }
    }
}
[dir="rtl"] {
    .progress-bar-title{ 
        border-radius: 0 0.313rem 0.313rem 0;
    }
}
/* End:: custom-progress */

/* Start:: custom-progress-2 */
.progress-item-1, .progress-item-2, .progress-item-3{
    position:absolute;
	margin-block-start:-0.25rem;
	z-index:1;
	height:0.938rem;
	width:0.938rem;
	border-radius:1.563rem;
    background-color: $light;
}
.progress-item-1{
	inset-inline-start:25%;
}
.progress-item-2{
	inset-inline-start:50%;
}
.progress-item-3{
	inset-inline-start:75%;
}
/* End:: custom-progress-2 */

/* Start:: custom-progress-3 */
.custom-progress-3 {
    overflow: visible;
    border-radius: 0.625rem;
    .progress-bar {
        position: relative;
        overflow: visible;
        margin: 0.313rem;
        border-radius: 0.25rem;
        &:before {
            content: "";
            position: absolute;
            width: 0.25rem;
            height: 0.25rem;
            inset-inline-start: 0.125rem;
            background-color: $custom-white;
            border-radius: 50%;
        }
        .progress-bar-value{
            border: 0.125rem solid $primary;
            width: 2.5rem;
            height: 1.563rem;
            line-height: 1.313rem;
            border-radius: 3.125rem;
            background: $custom-white;
            font-size: 0.625rem;
            font-weight: 600;
            color: $primary;
            text-align: center;
            position: absolute;
            inset-inline-end: -0.625rem;
            &.secondary {
                border: 0.125rem solid $secondary;
                color: $secondary;
            }
            &.warning {
                border: 0.125rem solid $warning;
                color: $warning;
            }
            &.info {
                border: 0.125rem solid $info;
                color: $info;
            }
            &.success {
                border: 0.125rem solid $success;
                color: $success;
            }
            &.danger {
                border: 0.125rem solid $danger;
                color: $danger;
            }
        }
    }
}
/* End:: custom-progress-3 */

/* Start:: custom-progress-4 */
.custom-progress-4 {
    &.progress {
        background-color: $primary-01;
        border-radius: 0.25rem;
        &.secondary {
            background-color: rgba(var(--secondary-rgb),0.1);
            .progress-bar-label {
                color: $secondary;
            }
        }
        &.warning {
            background-color: rgba(var(--warning-rgb),0.1);
            .progress-bar-label {
                color: $warning;
            }
        }
        &.info {
            background-color: rgba(var(--info-rgb),0.1);
            .progress-bar-label {
                color: $info;
            }
        }
        &.success {
            background-color: rgba(var(--success-rgb),0.1);
            .progress-bar-label {
                color: $success;
            }
        }
        &.danger {
            background-color: rgba(var(--danger-rgb),0.1);
            .progress-bar-label {
                color: $danger;
            }
        }
        .progress-bar {
            border-radius: 0.25rem;
        }
        .progress-bar-label {
            position: absolute;
            inset-inline-end: 0.313rem;
            font-weight: 600;
            font-size: 0.813rem;
            color: $primary;
        }
    }
}
/* End:: custom-progress-4 */